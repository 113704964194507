html, body {
  cursor: none;
 
}

.cursor {
  width: 60px;
  height: 60px;
  border: 60px solid #fefefe;
  border-radius: 100%;
  position: fixed;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 9999;
  mix-blend-mode: difference;
}