.mousecursor{
    position : absolute;
    width: 20px;
    height: 20px;
    background-color: brown;
    border-radius: 50%;
    pointer-events: none;
}






/* NAVIGATION */
.navtest {
  width: 80%;
  margin: 0 auto;
  background: #fff;
  padding: 50px 0;
  box-shadow: 0px 5px 0px #dedede;
}
.navtest ul {
  list-style: none;
  text-align: center;
}
.navtest ul li {
  display: inline-block;
}
.navtest ul li a {
  display: block;
  padding: 15px;
  text-decoration: none;
  color: #aaa;
  font-weight: 800;
  text-transform: uppercase;
  margin: 0 10px;
}
.navtest ul li a,
.navtest ul li a:after,
.navtest ul li a:before {
  transition: all .5s;
}
.navtest ul li a:hover {
  color: #555;
}


/* stroke */

.navtest .fill ul li a {
  position: relative;
}

.navtest .fill ul li a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: '.';
  color: transparent;
  background: #aaa;
  height: 1px;
}


.navtest .fill ul li a {
  transition: all 2s;
}

.navtest .fill ul li a:after {
  text-align: left;
  content: '.';
  margin: 0;
  opacity: 0;
}
.navtest .fill ul li a:hover {
  color: #fff;
  z-index: 1;
}
.navtest .fill ul li a:hover:after {
  z-index: -10;
  animation: fill 1s forwards;
  -webkit-animation: fill 1s forwards;
  -moz-animation: fill 1s forwards;
  opacity: 1;
}






/* Keyframes */
@-webkit-keyframes fill {
  0% {
    width: 0%;
    height: 1px;
  }
  50% {
    width: 100%;
    height: 1px;
  }
  100% {
    width: 100%;
    height: 100%;
    background: #333;
  }
}

